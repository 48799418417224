import { useEffect, useState } from 'react';
import { Spin, Button } from 'antd';
import { useAuth } from '@unbooking/ui-auth';
import './styles.scss';

const SignInPage = () => {
  const [loaderSpinning, setLoaderSpinning] = useState(false);
  const { authorize, firstLoginAttempt } = useAuth();

  useEffect(() => {
    if (firstLoginAttempt) {
      setLoaderSpinning(true);
      authorize?.();
    }
  }, [firstLoginAttempt, authorize]);

  return (
    <div className='sign-in-page flex-center-elements'>
      <div className='info-box flex-center-elements'>
        <p>Email Templates</p>
        <Spin spinning={loaderSpinning} />
        {!firstLoginAttempt && (
          <Button
            type='link'
            onClick={() => {
              setLoaderSpinning(true);
              authorize?.();
            }}
          >
            Sign In
          </Button>
        )}
      </div>
    </div>
  );
};

export default SignInPage;
