import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from '@unbooking/ui-auth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { authConfig, axios } from './common/utils';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/styles/index.scss';

const container = document.getElementById('root')!;
const root = ReactDOM.createRoot(container);
const queryClient = new QueryClient();

root.render(
  <StrictMode>
    <AuthProvider authConfig={authConfig} axiosInstance={axios}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Router>
    </AuthProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
