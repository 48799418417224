import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button, Table, TableColumnType, message, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { PageWrapper, SearchBar } from '../../components';
import { Template } from '../../common/types';
import { axios, getErrorMessageFromResponse } from '../../common/utils';
import './styles.scss';

interface TemplateData {
  key: number;
  name: string;
  created: string | Date;
}

const columns: TableColumnType<TemplateData>[] = [
  {
    title: 'Template name',
    dataIndex: 'name',
    key: 'name',
    render: (_, { name }) => <Link to={`edit-template/${name}`}>{name}</Link>,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Service Line',
    dataIndex: 'serviceLine',
    key: 'serviceLine',
  },
  {
    title: 'Creation date',
    dataIndex: 'created',
    key: 'created',
    sorter: (a, b) => {
      if (a.created instanceof Date && b.created instanceof Date) {
        return a.created.getTime() - b.created.getTime();
      }

      return 1;
    },
  },
];

const TemplatesListPage = () => {
  const { isLoading: templatesLoading, data: templatesData } = useQuery<Template[], AxiosError>({
    queryKey: ['templates'],
    queryFn: () => axios.get('/templates').then((response) => response.data.templates),
    throwOnError: (error, _) => {
      console.log(error.response?.data);
      message.error(`There was an error retrieving templates: ${getErrorMessageFromResponse(error)}`);
      return false;
    },
  });

  const [searchedTemplateName, setSearchedTemplateName] = useState<string>('');
  const [searchedServiceLine, setSearchedServiceLine] = useState<string>('');

  const serviceLines = useMemo(() => {
    const serviceLines = new Set<string>();
    templatesData?.forEach((template) => serviceLines.add(template.ServiceLine));
    return Array.from(serviceLines).sort((a, b) => a.localeCompare(b));
  }, [templatesData]);

  const filteredTemplatesData = useMemo(() => {
    return templatesData?.filter((template) => {
      return (
        template.Name.toLowerCase().includes(searchedTemplateName.toLowerCase()) &&
        (!searchedServiceLine || template.ServiceLine === searchedServiceLine)
      );
    });
  }, [templatesData, searchedTemplateName, searchedServiceLine]);

  const templatesTableData = useMemo(
    () =>
      filteredTemplatesData?.map((template, i) => ({
        key: i + 1,
        name: template.Name,
        created: DateTime.fromISO(template.CreatedTimestamp).setLocale('en').toFormat('ff'),
        serviceLine: template.ServiceLine,
      })),
    [filteredTemplatesData]
  );

  return (
    <PageWrapper title='Email templates'>
      <Spin spinning={templatesLoading}>
        <SearchBar
          serviceLines={serviceLines}
          onSearchSubmit={(values) => {
            setSearchedTemplateName(values.name || '');
            setSearchedServiceLine(values.serviceId || '');
          }}
        />
        <Table
          className='templates-list'
          columns={columns}
          dataSource={templatesTableData}
          pagination={{ hideOnSinglePage: true, showSizeChanger: true, pageSizeOptions: ['10', '20', '50'] }}
        />
      </Spin>
      <div className='buttons-group'>
        <Link to='/add-template'>
          <Button className='create-btn' type='primary' icon={<PlusOutlined />}>
            Add email template
          </Button>
        </Link>
      </div>
    </PageWrapper>
  );
};

export default TemplatesListPage;
