import axios, { AxiosInstance } from 'axios';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': String(process.env.REACT_APP_API_KEY),
  },
});

export default axiosInstance;
