import { ReactNode } from 'react';
import './styles.scss';

interface PageWrapperProps {
  title: string;
  headerButton?: JSX.Element | null;
  children: ReactNode
}

const PageWrapper = ({ title, children, headerButton = null }: PageWrapperProps) => {
  return (
    <div className='container'>
      <div className='page-header'>{title}</div>
      {headerButton && <div className='page-header-btn'>{headerButton}</div>}
      <main className='content'>{children}</main>
    </div>
  );
};

export default PageWrapper;
