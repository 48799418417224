import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Spin, Popconfirm, Tag, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { PageWrapper, EmailEditor } from '../../components';
import { axios, formValidationRules, convertTemplateNameToCode, getErrorMessageFromResponse } from '../../common/utils';
import { TemplateDetails } from '../../common/types';
import './styles.scss';
import { AxiosError, AxiosResponse } from 'axios';

interface EditTemplatePageProps {
  createMode?: boolean;
}

interface FormTemplateData {
  code: string;
  subject: string;
  html: string;
}

const EditTemplatePage = ({ createMode }: EditTemplatePageProps) => {
  const { code } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormTemplateData>();
  const [fieldsChanged, setFieldsChanged] = useState<boolean>(false);

  const {
    isLoading: templateLoading,
    isSuccess,
    data: templateData,
  } = useQuery<TemplateDetails>({
    queryKey: ['templates', code],
    enabled: !createMode && !!code,
    queryFn: () => axios.get(`/templates/${code}`).then((response) => response.data as TemplateDetails),
    throwOnError: (error, _) => {
      console.log(error);
      message.error(`There was an error getting the template: ${getErrorMessageFromResponse(error)}`);
      return false;
    },
  });

  const { mutateAsync: createTemplate, isPending: createTemplateLoading } = useMutation<
    AxiosResponse,
    AxiosError,
    Record<string, string>
  >({
    mutationKey: ['create-template'],
    mutationFn: (data) => axios.post('/templates', data),
    throwOnError: (error) => {
      console.log(error.response);
      message.error(`Error saving template: ${getErrorMessageFromResponse(error)}`);
      return false;
    },
  });

  const { mutateAsync: updateTemplate, isPending: updateTemplateLoading } = useMutation<
    AxiosResponse,
    AxiosError,
    Record<string, string>
  >({
    mutationKey: ['update-template'],
    mutationFn: (data) => axios.put(`/templates/${templateData?.code}`, data),
    throwOnError: (error) => {
      console.log(error.response);
      message.error(`Error saving template: ${getErrorMessageFromResponse(error)}`);
      return false;
    },
  });

  const { mutateAsync: deleteTemplate, isPending: deleteTemplateLoading } = useMutation<
    AxiosResponse,
    AxiosError,
    void
  >({
    mutationKey: ['delete-template'],
    mutationFn: () => axios.delete(`/templates/${templateData?.code}`),
    throwOnError: (error) => {
      console.log(error.response);
      message.error(`Error deleting template: ${getErrorMessageFromResponse(error)}`);
      return false;
    },
  });

  useEffect(() => {
    if (isSuccess && templateData) {
      form.setFieldsValue({
        code: templateData.code,
        subject: templateData.subject,
        html: templateData.html_part,
      });
    }
  }, [isSuccess, form, templateData]);

  const goBackToTemplatesList = () => {
    setTimeout(() => {
      navigate('/');
    }, 500);
  };

  const onSubmitHandler = async (formValues: FormTemplateData) => {
    const emailTemplateData: { [key: string]: string } = {
      subject: formValues.subject,
      html_part: formValues.html,
    };

    if (createMode) {
      emailTemplateData.code = convertTemplateNameToCode(formValues.code);
    }

    if (createMode) {
      const response = await createTemplate(emailTemplateData);

      if (response.status === 200) {
        message.success('Template was created!');
        form.setFieldsValue({ code: emailTemplateData.code });
        goBackToTemplatesList();
      }
    } else {
      const response = await updateTemplate(emailTemplateData);

      if (response.status === 200) {
        message.success('Template was saved!');
        form.setFieldsValue({ code: emailTemplateData.code });
        goBackToTemplatesList();
      }
    }
  };

  const deleteTemplateHandler = async () => {
    const response = await deleteTemplate();

    if (response.status === 200) {
      message.warning('Template was deleted');
      goBackToTemplatesList();
    }
  };

  const renderBackButton = (): JSX.Element => (
    <Link to='/'>
      <Button type='primary' icon={<ArrowLeftOutlined />}>
        Back to list
      </Button>
    </Link>
  );

  const pageTitle = createMode ? 'Add template' : 'Edit template';

  return (
    <PageWrapper title={pageTitle} headerButton={renderBackButton()}>
      <Spin spinning={templateLoading}>
        <Form
          name='template-editor'
          form={form}
          layout='vertical'
          onFinish={onSubmitHandler}
          onFieldsChange={() => setFieldsChanged(true)}
        >
          <Form.Item name='code' label='Template Name' rules={formValidationRules.code}>
            <Input disabled={!createMode && !!code} />
          </Form.Item>
          <Form.Item name='subject' label='Subject' rules={formValidationRules.subject}>
            <Input />
          </Form.Item>
          <Form.Item name='html' label='Email Body' rules={formValidationRules.body}>
            <EmailEditor />
          </Form.Item>
          {templateData?.required_context_variables?.length && !createMode ? (
            <section className='context-variables'>
              <h3>
                Please note:{' '}
                <p>
                  required context variables for this template:{' '}
                  {templateData.required_context_variables.map((variable, i, arr) => (
                    <div className='variable'>
                      <Tag>{`{{${variable}}}`}</Tag>
                      {i === arr.length - 1 ? '' : ','}
                    </div>
                  ))}
                </p>
              </h3>
            </section>
          ) : null}
          <Form.Item>
            <div className='buttons-group'>
              <Button
                type='primary'
                htmlType='submit'
                disabled={!fieldsChanged}
                loading={createMode ? createTemplateLoading : updateTemplateLoading}
              >
                {createMode ? 'Add new' : 'Save'}
              </Button>
              {!createMode && (
                <Popconfirm
                  title='Are you sure you want to delete this template?'
                  okText='OK'
                  cancelText='Cancel'
                  onConfirm={deleteTemplateHandler}
                >
                  <Button danger loading={deleteTemplateLoading}>
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </PageWrapper>
  );
};

export default EditTemplatePage;
