import React, { FC, useRef } from 'react';
import Editor from 'jodit-react';
import { editorConfig } from './config';

interface IEmailEditor {
  value?: string;
  onChange?: (value: string) => void;
}

const EmailEditor: FC<IEmailEditor> = ({ value = '', onChange }) => {
  const editor = useRef(null);

  return (
    <Editor ref={editor} value={value} config={editorConfig} onBlur={(editorContent) => onChange?.(editorContent)} />
  );
};

export default EmailEditor;
