export const convertTemplateNameToCode = (name: string) => {
  if (!name) return '';

  return name
    .trim()
    .toLowerCase()
    .replaceAll(' ', '-')
    .replaceAll(/-{2,}/g, '-')
    .replaceAll(/[^a-z0-9-]/gi, '');
};

export const getErrorMessageFromResponse = (err: any) => {
  let errorMessage = err.message;

  if (err?.response?.data?.detail) {
    errorMessage = err.response.data.detail;
  }

  return errorMessage;
};
