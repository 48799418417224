import { Button } from 'antd';
import { useAuth } from '@unbooking/ui-auth';
import { ReactComponent as WfpLogo } from '../../assets/img/svg/wfp.svg';
import './styles.scss';

const Header = () => {
  const { logout, userName } = useAuth();

  return (
    <header className='header'>
      <div className='status-bar'>
        <div className='status-container'>
          <div className='svg-container'>
            <WfpLogo height='40px' width='40px' />
          </div>
          <div className='user-info'>
            <span>
              Hello <b>{userName ?? ''}</b>
            </span>
            <Button className='btn-logout' type='link' onClick={logout}>
              Logout
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
