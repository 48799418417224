export const formValidationRules = {
  code: [
    {
      required: true,
      message: 'Template name is required',
    },
  ],
  subject: [
    {
      required: true,
      message: 'Email subject is required',
    },
  ],
  body: [
    {
      required: true,
      message: 'Email body is required',
    },
  ],
};
