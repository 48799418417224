import { Button, Form, Input, Select } from 'antd';
import './styles.scss';

interface FormValues {
  name?: string;
  serviceId?: string;
}

export interface SearchBarProps {
  serviceLines?: string[];
  onSearchSubmit?: (values: FormValues) => void;
}

const SearchBar = ({ serviceLines, onSearchSubmit }: SearchBarProps) => {
  return (
    <Form name='searchbar' onFinish={onSearchSubmit} layout='inline' className='searchbar'>
      <Form.Item<FormValues> name='name' className='input'>
        <Input placeholder='Please enter template name' />
      </Form.Item>
      <Form.Item<FormValues> name='serviceId'>
        <Select showSearch placeholder='Please select service' allowClear className='service-dropdown'>
          {serviceLines?.map((serviceLine) => (
            <Select.Option key={serviceLine} value={serviceLine}>
              {serviceLine}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item className='submit-btn'>
        <Button type='primary' htmlType='submit'>
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SearchBar;
