import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { ReactComponent as PoweredByLogo } from '../../assets/img/svg/powered_by_logo.svg';
import { supportEmail } from '../../common/constants';

const ErrorPage: FC = () => {
  const location = useLocation();

  return (
    <div className='no-access-page'>
      <div className='message-container'>
        <PoweredByLogo width={75} height={75} />
        <h3 className='text'>
          Ops, there was an error: {location.state?.errorMessage ?? 'Server error'}. If you keep getting this error
          please contact support: <a href={`mailto: ${supportEmail}`}>{supportEmail}</a>
        </h3>
      </div>
    </div>
  );
};

export default ErrorPage;
