export const LocalStorageKeys = {
  USERNAME: 'username',
  EMAIL: 'email',
  EXPIRATION: 'expiration',
  ID_TOKEN: 'idToken',
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  PERMISSION_GRANTED: 'permissionGranted',
};

export const Env = {
  DEV: 'dev',
  QA: 'qa',
  PROD: 'prod',
};

export const supportEmail = process.env.REACT_APP_EMAIL_SUPPORT ?? 'global.accomodation@wfp.org';