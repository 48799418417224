import { Spin } from 'antd';
import './styles.scss';

const CallbackPage = () => {
  return (
    <div className='loading-container'>
      <Spin spinning size='large' />
    </div>
  );
};

export default CallbackPage;
